import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import cucHeader from "../assets/images/cuc_header.jpg";
import cuc_MD from "../assets/images/cucine_MD.jpg";
import cuc_McD from "../assets/images/cucine_McD.jpg";
import cuc_BK from "../assets/images/cucine_BK.jpg";
import cuc_Patrick from "../assets/images/patrick.jpeg";

const stile = {
  bottone: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    margin: "0 auto",
    display: "table-cell",
    verticalAlign: "middle",
    marginBottom: 20,
    padding: 30,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    textAlign: "center",
    textTransform: "uppercase",
  },
  tabella: {},
  imgCategoria: {
    width: "100%",
  },
  ulCosa: {
    display: "flex",
    justifyContent: "space-around",
  },
};

class EquipmentCuc extends React.Component {
  render() {
    return (
      <Layout>
        <div id="main">
          <section id="content" className="main">
            <span className="image main">
              <img src={cucHeader} alt="Equipment Cucine - Giuvi srl" />
            </span>
            <h2>FoodService Kitchen Equipment Assistance</h2>
            <p>
              Una sfida che è diventata una solida realtà e Core Business di
              Giuvi. Grazie alla padronanza di mestiere del nostro responsabile
              tecnico abbiamo trasformato questa sfida in un settore in continua
              crescita che si colloca in un ambiente di nicchia tra clienti di
              caratura internazionale come McDonalds e Burger King.
              <br />
              <br />
              Oggi Giuvi è un centro assistenza tecnica certificato da tutti i
              maggiori brand di food equipment con tecnici dedicati e formati,
              parco mezzi e magazzino ricambi di importante valore. La nostra
              struttura attuale ci permette di garantire un servizio
              estremamente competente di pronto intervento ed una immediata
              risposta alle esigenze del cliente.
            </p>
          </section>
          <section className="main special">
            <header className="major">
              <h2>MAGGIORI MARCHI GESTITI</h2>
            </header>
            <ul className="features" style={stile.ulCosa}>
              <li>
                <span className="image">
                  <img src={cuc_McD} alt="" style={stile.imgCategoria} />
                  McDONALD'S
                </span>
              </li>
              <li>
                <span className="image">
                  <img src={cuc_BK} alt="" style={stile.imgCategoria} />
                  BURGER KING
                </span>
              </li>
              <li>
                <span className="image">
                  <img src={cuc_MD} alt="" style={stile.imgCategoria} />
                  MD DISCOUNT
                </span>
              </li>
            </ul>
          </section>
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>FIGURA DI RIFERIMENTO</h2>
                </header>
                <h3>Tobaldo Patrick</h3>
                <p>
                  Sono il responsabile tecnico di settore, nonché socio di
                  Giuvi. Ho maturato la mia esperienza ventennale nel
                  foodservice equipment collaborando con i più grandi brand
                  internazionali nel settore fast e slow food. Gestisco il mio
                  gruppo con estrema passione e dedizione; credo
                  nell'organizzazione e nel lavoro di squadra. La tecnicità del
                  nostro mestiere è uno stimolo costante alla crescita e fonte
                  di continua soddisfazione.
                </p>
                <ul className="actions">
                  <li>
                    <a href="tel:+393498686589">
                      <span className="icon style3 fa-phone"></span> (+39) 393
                      90 02 576
                    </a>
                  </li>
                  <li>
                    <a href="mailto:patrick@giuvi.it">
                      <span className="icon style3 fa-envelope"></span>{" "}
                      patrick@giuvi.it
                    </a>
                  </li>
                  {/*  comment here  
                  <li>
                    <a href="#">
                      <span className="icon style3 fa-linkedin"></span> Profilo
                      LinkedIn
                    </a>
                  </li>
                  */}
                </ul>
              </div>
              <span className="image">
                <img src={cuc_Patrick} alt="" />
              </span>
            </div>
          </section>
          <section className="main special">
            <Link to="/" className="button" style={{ margin: "0 auto" }}>
              Torna alla Home
            </Link>
          </section>
        </div>
      </Layout>
    );
  }
}

export default EquipmentCuc;
